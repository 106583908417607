body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  /*font-size: .875rem;*/

  font-family: Roboto, sans-serif !important;
  font-size: 1rem;


}
b , strong {
  font-size: 16px !important;
  font-weight: bolder;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}
li {
  text-align: -webkit-match-parent;
}
figure {
  margin: 0 0 1rem;
}
img {
  opacity: 1;
  transition: opacity 0.3s;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
}
a {
  color: #0b5cad;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
}
img {
  vertical-align: middle;
  border-style: none;
}
* {
  font-variant-ligatures: none;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
svg {
  width: 10px;
  height: 24px;
  fill: none;
  overflow: hidden;
}