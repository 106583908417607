.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header-logged-out {
  min-height: 50px;
  z-index: 1000;
  position: fixed;
  top: calc(0px + 0px);
  left: 0;
  right: 0;
  background-color: #171321;
  /*font-size: calc(10px + 2vmin);*/

  /*display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;*/
}
.container-fluid {
  width: 100%;
  /*padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
.header-logged-out-nav {
  display: flex;
  justify-content: space-evenly;
  gap: 0.5rem;

  position: relative;
  min-height: 48px;
}
.nav-logged-out-logo {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}
.span-logo-header {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.header-logged-out-logo {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-left: -4px;
  height: 32px;
  width: auto;
  border-radius: .25rem;
}
.brand-header-logo {
  height: 100%;
  width: auto;
}
.header-ul-item-first {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  /*flex-grow: 1;*/
}
.header-ul-item-second {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}
.header-logged-out-nav-item > a {
  display: inline-block;
  padding: 6px 8px;
  height: 32px;
  border-radius: .25rem;
  color: #dcdcde;
  font-weight: 400;
  font-size: .875rem;
}
.header-logged-out-nav-item > Link {
  display: inline-block;
  padding: 6px 8px;
  height: 32px;
  border-radius: .25rem;
  color: #dcdcde;
  font-weight: 400;
  font-size: .875rem;
}
.header-logged-out-nav-item > a:hover {
  background-color: #45424d;
  text-decoration: none;
  cursor: pointer;
}
.header-logged-out-nav-item > a:active {
  background-color: #74717a;
}
.header-btn-premium {
  background-color: #5c87c3;
  border-radius: 20px;
  margin-left: 10px;
  color: white;
  width: 95px;
  height: 35px;
  border: none;
}
.header-btn-premium:hover {
  background-color: #6a71b9;
  cursor: pointer;
}
.header-btn-premium:active {
  transform: scale(97%);
}
.header-btn-signin {
  background-color: #e2e2e2;
  border-radius: 20px;
  margin-left: 10px;
  color: #131313;
  width: 75px;
  height: 35px;
  border: none;
}
.header-btn-signin:hover {
  cursor: pointer;
  background-color: #ececec;
}
.header-btn-signin:active {
  transform: scale(97%);
}
.hero-temp-mail-container {
  max-width: 556px;
  border-radius: 10px;
  margin: 10px;
}
.temp-mailbox {
  /*border: 1px solid rgba(73, 214, 145, 0.2);*/
  height: 250px;
  color: white;
  text-align: center;
  /*padding: 10px;*/
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding: 4px;
  background-color: hsla(0, 0%, 100%, .04);
  /*hsl(0deg 0% 0% / 18%);*/
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: linear-gradient(rgba(73, 214, 145, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

}
.hero-ads-container {
  margin: 10px;
}
.main-hero-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background-color: #171321;
  color: #acacac;
  flex-wrap: wrap;
}
.temp-mail-under-text p{
  padding: 10px;
  text-align: center;
  color: #7a7c80;
  font-size: 14px !important;
  font-family: Roboto Mono, monospace !important;
  font-weight: 500 !important;
  margin: 0
}
main {
  padding: 20px 0 40px;
  position: relative;
  background-color: #ffffff;
}
.container {
  width: 100% !important;
  padding: 0 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  /*margin-right: -15px;
  margin-left: -15px;*/
  justify-content: center;
}
.tm-content{
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: 1724px;

}
.inbox-wrap-main {
  border: 1px solid #e5e5f0;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
}
.ts-inbox-section-ad-placement{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.inbox-header {
  background-color: rgb(243, 243, 243);
  color: #2d2d2d;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 10px 10px 0 0;
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  /*background: linear-gradient(rgba(73, 214, 145, 0.2) 0%, rgba(255, 255, 255, 0) 100%);*/
}
.col-h1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 25px;
  font-size: 1rem;
  font-weight: 700;
  font-family: Roboto Mono, monospace !important;
  /*text-transform: uppercase;*/
}
.small-h{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
  /*text-transform: uppercase;*/ 
  font-family: Roboto Mono, monospace !important;;
}
.inbox-empty {
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
.inbox-empty-ms-g {
  text-align: center;
  width: 100%;
}
.empty-box-title {
  color: #585d6a;
  font-size: 20px;
  font-family: Roboto Mono, monospace !important;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}
svg {
  margin-bottom: 25px;
  vertical-align: middle;
  overflow: hidden;
}
.inbox-no-main-desc{
  color: #8c92a5;
  font-family: Roboto Mono, monospace !important;
  font-weight: 400;
  font-style: normal;
}
@media (min-width: 992px) {
  .inbox-wrap-main {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 800px;
  }
}
@media (min-width: 0px) and (max-width: 1199px) {
  .tm-content {
    width: 100%;
  }
}
.no-emails-placeholder-img {
    width: 60%;
    height: auto;

}
.no-emails-loading-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.loading-gif-no-emails {
  width: 50px;
}
.mid-intro-text {
  text-align: center;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.mid-intro-text-h1{
  font-size: 28px !important;
  color: #22242b;
  line-height: 1.2 !important;
  margin-top: 20px !important;
  margin-bottom: 8px !important;
  padding-bottom: 20px;
}
.mid-intro-text-p {
  color: #585d6a;
  line-height: 24px;
  margin-bottom: 20px;
  max-width: 800px;
}
.mid-intro-text-p-long {
    color: #585d6a;
    line-height: 24px;
    margin-bottom: 20px;
}
.section-popular-articles {
  border-top: 1px solid #e5e5f0;
  padding-top: 30px;
}
.p-header {
  padding: 20px 0;
  text-align: center;
}
.p-header-h3 {
  font-size: 28px !important;
  line-height: 1.2 !important;
  color: #22242b !important;
  margin: 15px 0 5px !important;
  padding: 0 !important;
  text-align: center !important;
}
.ul-popular-article-list{
  list-style-type: none;
  padding: 0;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 0;
  margin-bottom: 1rem;
  justify-content: center;
}
.col-xs-12 {
  max-width: 1324px;
}
.li-col-sm {
  margin-left: 0 !important;
  padding-top: 18px;
  padding-bottom: 18px;
  flex: 0 0 100%;
  max-width: 550px;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.li-inner-text-box {
  padding-right: 190px;
  border-radius: 15px;
  border-bottom: 24px solid #fff;
  background-color: #fff;
  position: relative;
  padding: 24px 170px 0 24px;
  height: 172px;
  overflow-y: hidden;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}
.popular-articles-figure {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 124px;
  height: 124px;
  overflow: hidden;
  border-radius: 10px;
  display: block;
}
.figure-image{
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}
.inner-text-box-a {
  font-weight: 500;
  font-size: 16px !important;
  color: #22242b !important;
  font-family: Roboto, sans-serif !important;
  margin: 0;
  padding-bottom: 12px;
  text-decoration: none;
  display: block;
}
.inner-text-box-a:hover {
  color: #5490ff !important;
  text-decoration: none;
}
.inner-text-box-p {
  font-size: 14px;
  color: #8c92a5;
  font-weight: 400;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
.popular-articles-ad {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bottom-seo-text {
    max-width: 1150px;
    padding-left: 15px;
    padding-right: 15px;
}
.bottom-seo-text h2 {
    font-size: 20px !important;
    color: #22242b;
    line-height: 1.2 !important;
    margin-top: 14px !important;
    margin-bottom: 8px !important;
    padding-bottom: 8px;

    font-family: Roboto Mono, monospace !important;
    font-weight: 700 !important;

}
.bottom-seo-text p {
    color: #585d6a;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 20px;
}
.bottom-seo-text ul {
    padding-left: 16px;
}

.bottom-seo-text ul li{
    color: #585d6a;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 10px !important;
}
.input-box-col {
  padding: 4px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.input-wrap {
  padding: 10px;
  background-color: hsl(0deg 0% 100% / 18%);
  border-radius: 30px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
/*.input-wrap input {
  border: 0;
  font-family: Roboto, sans-serif !important;
  background: transparent;
  margin: 0;
  padding: 5px 5px;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #fff;
  height: 52px;
  min-width: 340px;
}*/
.random-loading-div {
  margin-right: 20px;
}
.temp-mailbox h2{
  font-size: 20px !important;
    color: #fff !important;
    line-height: 100% !important;
    margin-top: 0 !important;
    padding-top: 0;
    margin-bottom: 25px !important;
    margin-bottom: .5rem;
    font-family: Roboto Mono, monospace !important;
    font-weight: 700 !important;
    line-height: 1.2;
    color: #000;
}
.h2 .h3 {
  margin-bottom: .5rem;
    font-family: Roboto Mono, monospace !important;
    font-weight: 700 !important;
    line-height: 1.2;
    color: #000;
}
.temp-email-up {
  margin-bottom: 20px;
}
.random-email-text-up {
  border: 0;
    font-family: Roboto, sans-serif !important;
    background: transparent;
    margin: 0;
    padding: 10px 15px;
    /* font-size: 20px !important; */
    font-weight: 400 !important;
    color: #fff;
    /* height: 50px; */
    /* min-width: 340px;*/
    
}
.custom-mail-div-up {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.temp-box-refresh-btn {
  height: 40px;
  width: 40px;
  background-color: hsl(0deg 0% 100%);
  border: none;
  /* color: white; */
  cursor: pointer;
  border-radius: 50%;
}
.temp-box-refresh-btn svg {
  width: 50px;
  height: 50px;
}
.temp-box-refresh-btn:active {
  transform: scale(80%);
}
.temp-box-apply-btn {
  height: 50px;
  width: 50px;
  /* background: linear-gradient(rgba(73, 214, 145, 0.2) 0%, rgba(255, 255, 255, 0) 100%); */
  border: none;
  /* color: white; */
  cursor: pointer;
  color: white;
  border-radius: 50%;
  margin: 4px;
}
.temp-box-apply-btn:active {
  transform: scale(90%);
}

.custom-domain-input-sd40 {
  height: 40px;
  border: none;
  border-radius: 7px;
  background-color: #cbe5cf42;
  color: white;
  padding: 10px;
  margin: 4px;
}
.input-ser45 {
  height: 40px;
  padding: 5px;
  background-color: #484e51;
  border: none;
  color: #f9f9f9;
  border-radius: 5px;
  margin: 4px;
  font-family: 'Roboto Mono';
}
.email-domain-option {

}
.email-copy-btn {
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  cursor: pointer;
}
.email-copy-btn:active {
  transform: scale(90%);
}
.email-confirm-sd30-section {
  background-color: #d5d5d5;;
}
.email-confirm-child-div {
  padding: 16px;
}
.system-wide-sel-email {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.confirmed-email-container {
  background-color: #21393799;;
  padding: 10px;
  border-radius: 2px;
  color: white;
  /* font-family: Roboto, mono !important; */
  font-size: 14px !important;
  font-family: Roboto Mono, monospace !important;
  /*font-weight: 500;*/
  font-optical-sizing: auto;
}
.footer-common-btn {
  margin: 5px;
  border: none;
  border-radius: 2px;
  padding: 5px;
  background-color: #171321;
  color: white;
  cursor: pointer;
}
.footer-common-btn:hover {
  background-color: #4a4848;
}


































.btn_navbar {
  border: none;
  height: 50px;
  width: 69px;
  /* border-radius: 15px; */
  margin: 2px;
  color: #ffffff;
  background-color: #222227;
  /* cursor: pointer;*/
}
.btn_navbar:hover {
  background-color: rgb(93 125 197 / 90%);
}
.nav_btn_group_1 {
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-content: center;
align-items: center;
width: 95%;
justify-content: space-between;
}
.nav_btn_group_2 {
width: 20%;
display: flex;
flex-direction: row;
align-content: center;
justify-content: space-evenly;
align-items: center;
}
.btn_navbar_login {
  border: none;
  width: 80px;
  height: 50px;
  background-color: #222227;
  /* border-radius: 30px; */
  color: white;
}
.btn_navbar_login:hover{
  background-color: #bd1158;
}
.premium-main-nav {
  width: 95px;
  height: 40px;
  border: none;
  background-color: #5c87c3;
  border-radius: 20px;
  margin-left: 10px;
  color: white;
}
.premium-main-nav:hover {
  background-color: #c3a8ed;
}
.footer_main_div {
  background-color: #171321;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 10px;
  color: white;
}
.footer_site_map_btn {

}
.root_div {
  /*height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.main_container {
  width: 100%;
  /* height: 90vh; */
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 50px;

}
.main_bottom_screen_ad_place_down_1 {
  width: 90%;
  background-color: #fdfdfd;
  color: black;
  height: 100px;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}
.container_main_holding {
  width: 100%;
  /*background-color: #ffffff;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  color: black; */
}
.inbox-active-ms-g {
  border-radius: 0 0 10px 10px;
  min-height: 333px;
  padding-bottom: 3px;
  width: 100%;
}
.inbox-details-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.inbox-details-li {
  border-radius: 0 0 10px 10px;
  background-color: #f6f7f9;
  height: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 15px;
  margin-top: 5px;
}
.col-box {
  position: relative;
  max-width: 40%;
  flex-wrap: wrap;
  display: flex;
  flex: auto;
  justify-content: flex-end;
}
.view-link {
  display: inline-block;
  width: 100%;
  text-align: justify;
}
.bullet-icon {
  background-color: #00c099;
  width: 6px;
  height: 6px;
  position: absolute;
  left: -16px;
  top: 3px;
  /*background-color: #e5e5f0;*/
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
}
.inbox-sender-name {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif !important;
  position: relative;
  width: 100%;
  color: #22242b;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 92%;
}
.inbox-sender-ellipsis {
  font-size: 14px;
  color: #8c92a5;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 92%;
}
.inbox-subject-xs-m {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  position: relative;
  max-width: 40%;
  display: flex;

}
.inbox-subject {
  color: #585d6a;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif !important;
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: justify;
}
.attachment {
  flex: 0 0 20%;
  position: relative;
  max-width: 50%;
  margin-right: 10px;
}
.view-link-attachment {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.m-link-view {
  flex: 0 0 20%;
  position: relative;
  max-width: 50%;
}
.m-link-view-a {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
;
}














.main_side_screen_ad_place_right_1 {

    background-color: #ffffff;
    /* color: black; */
    margin: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    font-size: 14px;
    /* height: 600px; */
    align-items: center;
}
.right_side_ad_div_holder {
    height: 600px;
    background-color: #fdfdfd;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.footer_site_map_btn {
  margin-right: 20px;
}
.random_email_section {
  width: 98%;
}
.random_email_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}
.custom_email_section {
  width: 98%;
}
.custom_email_div {
  margin: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.custom_email_creation_notice {
  text-align: center;
  color: #6d6d6d;
  margin-bottom: 10px;
}
.selected_email_confirmations_div {
  width: 98%;
  text-align: center;
  margin: 9px;
  margin-bottom: 20px;
}
.emails_holder_section {
  width: 98%;
  padding: 5px;
  border: 1px solid #efefef;
  /* border-radius: 3px; */
  background-color: #ffffff;
}
.email_list_element {
  margin: 5px;
  cursor: pointer;
  border: 1px solid #f3ecec;
  padding: 10px;
  background-color: #ffffff;
}
.email_subject {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.from_txt {
  color: #4a4848;
}
.main_proceed_btn {
  margin-left: 5px;
  height: 30px;
  width: 70px;
  border: none;
  background-color: #1b5261;
  border-radius: 4px;
  color: white;
}
.main_proceed_btn:active{
  transform: rotate(34deg);
}
.back_home_section {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}
.read_email_notice_parag {
  width: 90%;
  text-align: center;
}
.respond_func_notice{
  color: #0083d3;
  font-family: system-ui;
}
.email_explorer_holder_section {
  width: 95%;
  padding: 5px;
  border: 1px solid #dddddd;
  /* border-radius: 3px; */
  background-color: #ffffff;
  margin-top: 30px;
}
.go_back_btn {
  border: none;
  width: 80px;
  height: 30px;
  background-color: #1b5261;
  color: white;
  border-radius: 4px;
}
.go_back_btn:active {
  transform: rotate(-34deg);
}
.random_mailbox_generation_notice {
  text-align: center;
  color: #6d6d6d;
}
.random_mailbox_span {
  margin: 10px;
  padding: 10px;
  border-radius: 0px;
  background-color: #f1f1f1;
  color: #252729;
  font: -webkit-control;
  padding-right: 20px;
  padding-left: 20px;
}
.selected_mailbox_span {
  margin: 10px;
  padding: 10px;
  border-radius: 0px;
  background-color: #aef994;
  color: #252729;
  font: -webkit-control;
  padding-right: 20px;
  padding-left: 20px;
}
.no_emails_div {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.no_emails_img {
  width: 500px;
}
.loading_email_progress_div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.loading_inbox_img {
  width: 200px;
}
.email_elem_link {
  text-decoration: none;
  color: inherit;
}
.email_elem_subject {
  color: #6ea7ff;
  font-style: inherit;
  font-size: inherit;
}
.email_in_progress_img_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.reading_email_img {
  width: 210px;
}
.check_mail_btn {
  border: none;
  color: white;
  padding: 10px;
  background-color: #48c2f1;
  border-radius: 4px;
}
.check_mail_btn:active{
  border: 2px solid #103b98;
}
.generate_random_mailbox_btn {
  border: none;
  background-color: #48c2f1;
  padding: 10px;
  border-radius: 4px;
  color: white;
}
.generate_random_mailbox_btn:active{
  border: 2px solid #103b98;
}
.email_read_header {
  border-bottom: 1px solid #c1baba;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
  background-color: #fcfcfc;
  padding: 10px;
}
.email_body_html {
  display: flex;
  align-items: center;
  justify-content: center;

}
.email_body_holder {
  width: 92%;
  border: 1px solid #f5f5f5;
  padding: 15px;
  border-radius: 6px;
}
.bottom_attachments_border_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bottom_attachments_border_div {
  border-bottom: 1px solid #bbbbbb;
  width: 40%;
  margin: 15px;
}
.email_attachments_holder {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
}
.email_attachments_inner {
  width: 90%;
  border: 1px solid #e9e9e9;
  padding: 8px;
  margin-bottom: 10px;
}
.appended_attachments_div {
  background-color: #fcfcfc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;

}
.attachments_elem_holder {
  margin: 3px;
}
.attachments_elem_link {
  text-decoration: none;
}
.msg_header_span{

}
